import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    $(this.element).find('#clone_id').on('change', this.onChangeQuestionbank)
  }

  async onChangeQuestionbank(event) {
    const id = event.target.value;
    const form = $(event.target).parents('form');
    const url = form.data('exam-sessions-url') + '&id=' + id;
    const response = await get(url,
      {
        headers: {
          Accept: "application/json"
        }
      }
    )

    if (response.ok) {
      const options = await response.json;

      var examsSelect = form.find('#clone_exam_sessions_to_include');
      examsSelect.empty();

      $.each(options, function(_, value) {
        examsSelect.append($("<option></option>")
          .attr("value", value[1]).text(value[0]));
      });
    }
  }
}